import axios from 'axios'

export const HTTP = axios.create({
    baseURL: process.env.VUE_APP_API_URL || 'https://api.kolel.org',
    headers: {
        token: `${localStorage.getItem("token")}`,
    }
})


export default {
    login(params) {
        return HTTP.post('/users/login.json', params)
    },

    loginWithGoogle(params) {
        return HTTP.post('/users/google_login.json', params)
    },

    getChannels() {
        return HTTP.get('/users/permissions')
    },

    getStatus(channelId, header) {
        return HTTP.get(`/channels/${channelId}/stats`, { headers: {app_language: header ? header : 'he'} })
    },

    getAllChannelVideos(channelId) {
        return HTTP.get(`/channels/${channelId}/videos`)
    },

    getChannelVideos(params, header) {
        return HTTP.get('/videos.json', { params: params, headers: {app_language: header ? header : 'he'} })
    },

    getProcessingVideos(params, header) {
        return HTTP.get('/video_in_processings.json', { params: params, headers: {app_language: header ? header : 'he'} })
    },

    countProcessingVideos(params) {
        return HTTP.get('/video_in_processings/count.json', { params: params })
    },

    retryProcessingVideos(params) {
        return HTTP.post(`/video_in_processings/retry.json`, params)
    },

    deleteProcessingVideos(params) {
        return HTTP.delete(`/video_in_processings/delete.json`, { params: params })
    },

    getVideo(videoId) {
        return HTTP.get(`/videos/${videoId}.json`)
    },

    postVideo(params) {
        return HTTP.post(`/videos.json`, params)
    },

    createChannel(params) {
        return HTTP.post(`/channels.json`, params)
    },

    checkCustomLink(params) {
        return HTTP.post('/channels/check_custom_link.json', params);
    },

    searchChannels(term, o_n_o) {
        const with_o_n_o = o_n_o ? `&o_n_o=${o_n_o}` : '';
        return HTTP.get(`/channels/autocomplete.json?term=${term}${with_o_n_o}`)
    },

    editVideo(videoId, params) {
        return HTTP.put(`/videos/${videoId}.json`, params)
    },

    redownloadVideo(videoId) {
        return HTTP.post(`/videos/${videoId}/redownload.json`)
    },

    getVideoComments(videoId) {
        return HTTP.get(`/videos/${videoId}/comments.json`)
    },

    editChannel(channelId, params) {
        return HTTP.put(`/channels/${channelId}.json`, params)
    },

    deleteChannel(channelId) {
        return HTTP.delete(`/channels/${channelId}`)
    },

    getImageStorageSignedUrl(channelId, contentType) {
        return HTTP.post(`/channels/${channelId}/presign_url`, { type: contentType })
    },

    getChannelInfo(channelId) {
        return HTTP.get(`/channels/${channelId}.json`)
    },

    getVideoStorageSignedUrl(channelId, contentType) {
        return HTTP.post(`/videos/presign_url`, { channel_id: channelId, type: contentType })
    },

    postStory(params) {
        return HTTP.post(`/stories.json`, params)
    },

    getStory(storyId) {
        return HTTP.get(`/stories/${storyId}.json`)
    },

    getChannelStories(params) {
        return HTTP.get(`/stories.json`, { params: params })
    },

    deleteChannelStory(storyId) {
        return HTTP.delete(`/stories/${storyId}.json`)
    },

    editStory(storyId, params) {
        return HTTP.put(`/stories/${storyId}.json`, params)
    },

    postEvent(params) {
        return HTTP.post(`/events.json`, params);
    },

    getChannelEvents(params) {
        return HTTP.get(`/events.json`, { params: params })
    },

    deleteEvent(eventId) {
        return HTTP.delete(`/events/${eventId}.json`)
    },

    editEvent(eventId, params) {
        return HTTP.put(`/events/${eventId}.json`, params)
    },

    getEvent(eventId) {
        return HTTP.get(`/events/${eventId}.json`)
    },

    getEventLocationSearch(params) {
        return HTTP.get(`/places/search.json`, { params: params })
    },

    getChannelReports(params) {
        return HTTP.get(`/video_reports.json`, { params: params })
    },

    ignoreReport(report) {
        return HTTP.put(`/video_reports/${report.id}.json`, { ignore: true })
    },

    getComments(params) {
        return HTTP.get(`/comments.json`, { params: params })
    },

    removeComment(comment) {
        return HTTP.delete(`/comments/${comment.id}.json`)
    },

    getHevruta(params) {
        return HTTP.get(`/newsletter_subscribes.json`, { params: params })
    },

    getUsers(params) {
        return HTTP.get(`/users.json`, { params: params })
    },

    getChannelsCatalog(params) {
        return HTTP.get(`/channels.json`, { params: params })
    },

    getUser(userId) {
        return HTTP.get(`/users/${userId}.json`)
    },

    getUserPermissions(user) {
        return HTTP.get(`/users/${user.id}/permissions.json`)
    },

    changePermission(user, channel, addOrRemove) {
        return HTTP.put(`/users/${user.id}/permissions.json`, {
            channel_id: channel.id,
            add_or_remove: addOrRemove,
        });
    },

    sendNotification(userId, body) {
        return HTTP.post(`/users/${userId}/send_notification`, body);
    },

    getRecommended(term, limit, offset, filter, channelId)
    {
        return HTTP.get(`/videos/recommended.json`, { params: { term: term, limit: limit, offset: offset, filter: filter, channel_id: channelId }})
    },

    getUserAnalytics(params)
    {
        return HTTP.get(`/admins/dashboard/users_data.json`, { params: params });
    },

    getTotalUserCount()
    {
        return HTTP.get(`/admins/dashboard/total.json`, {});
    },

    getMonthlyActiveUsers(params)
    {
        return HTTP.get(`/admins/dashboard/active_users.json`, { params });
    },

    getFollowData(params)
    {
        return HTTP.get(`/admins/dashboard/follows_data.json`, { params });
    },

    getVideoViewsAnalytics(params)
    {
        return HTTP.get(`/admins/dashboard/video_views_data.json`, { params: params });
    },

    getVideoDurationsAnalytics(params)
    {
        return HTTP.get(`/admins/dashboard/video_duration_data.json`, { params: params });
    },
    
    getVideoCategories(params = {}, header) {
        return HTTP.get(`/admins/categories`, { params: params, headers: {app_language: header ? header : 'he'} });
    },

    saveVideoCategories(params) {
        return HTTP.put(`/admins/categories/batch_update`, params);
    },

    postSynagogue(params) {
        return HTTP.post(`/synagogues.json`, params);
    },
    getSynagogues(params) {
        return HTTP.get(`/synagogues.json`, { params: params });
    },
    getPlaceTypes() {
        return HTTP.get(`/places/place_types.json`);
    },
    getPlaces(params) {
        return HTTP.get(`/places.json`, { params: params });
    },
    postPlace(data, params) {
        return HTTP.post(`/places.json`, data, { params: params });
    },
    deletePlace(placeId, params) {
        return HTTP.delete(`/places/${placeId}.json`, { params: params });
    },
    getPlace(placeId, params) {
        return HTTP.get(`/places/${placeId}.json`, { params: params });
    },
    editPlace(placeId, data, params) {
        return HTTP.put(`/places/${placeId}.json`, data, { params: params });
    },
    searchPlace(params) {
        return HTTP.get(`/places/search.json`, { params: params });
    },
    getCategories(header) {
        return HTTP.get(`/categories.json`, {headers: {app_language: header ? header : 'he'}});
    },
    getCategory(categoryId) {
        return HTTP.get(`/categories/${categoryId}`)
    },
    deleteCategory(categoryId) {
        return HTTP.delete(`/admins/categories/${categoryId}`)
    },
    createCategory(params) {
        return HTTP.post(`/admins/categories`, params)
    },
    editCategory(categoryId, params) {
        return HTTP.put(`/admins/categories/${categoryId}`, params);
    },
    getVideoPositions(categoryId, limit, offset, header) {
        return HTTP.get(`/admins/video_positions`, { params: {category_id: categoryId, limit: limit, offset: offset}, 
        headers: {app_language: header ? header : 'he'} })
    },
    editVideoPosition(videoPositionId, parmas) {
        return HTTP.put(`/admins/video_positions/${videoPositionId}`, null, { params: parmas});
    },
    createVideoPosition(data) {
        return HTTP.post(`/admins/video_positions`, data);
    },
    deleteVideoPosition(videoPositionId) {
        return HTTP.delete(`/admins/video_positions/${videoPositionId}`);
    },
    deleteVideoFromFeed(videoId, categoryId) {
        return HTTP.delete(`/videos/${videoId}/remove_from_category.json?category_id=${categoryId}`);
    },
    deleteVideo(videoId) {
        return HTTP.delete(`/admins/videos/${videoId}`);
    },
    createNotification(data) {
        return HTTP.post(`/notifications`, data);
    },
    getAppVersions() {
        return HTTP.get(`/app_versions.json`);
    },
    createAppVersion(data) {
        return HTTP.post(`/app_versions.json`, data);
    },
    editAppVersion(versionId, data) {
        return HTTP.put(`/app_versions/${versionId}.json`, data);
    },
    deleteAppVersion(versionId) {
        return HTTP.delete(`/app_versions/${versionId}.json`);
    },
    uploadBiographyImg(channelId, data) {
        return HTTP.post(`/channels/${channelId}/biography/upload_image`, data).headers({token: `${localStorage.getItem("token")}`, "Content-Type": "multipart/form-data" })
    },
    getChannelConversations(channelId, limit, offset) {
        return HTTP.get(`/channels/${channelId}/conversations.json`, { params: {limit: limit, offset: offset} });
    },
    getChannelConversationById(channelId, conversationId) {
        return HTTP.get(`/channels/${channelId}/conversations/${conversationId}.json`);
    },
    getConversationMessages(channelId, conversationId, limit, offset) {
        return HTTP.get(`/channels/${channelId}/conversations/${conversationId}/messages`, { params: {limit: limit, offset: offset}});
    },
    markAllSeenConversation(channelId, conversationId) {
        return HTTP.post(`/channels/${channelId}/conversations/${conversationId}/seen_all`);
    },
    createConversationMessage(channelId, conversationId, data) {
        return HTTP.post(`/channels/${channelId}/conversations/${conversationId}/messages`, data);
    },
    async getPlaylists(channelIds) {
        try {
            const promises = channelIds.map((channelId) => HTTP.get(`/channels/${channelId}/playlists`));
            const res = await Promise.all(promises);
            return res?.map(({ data }) => data.playlists).flat(1);
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
    getAllPlaylists(params) {
        return HTTP.get(`/playlists/get_items`, { params });
    },
    showPlaylist(id) {
        return HTTP.get(`/playlists/show_item`, { params: { id } });
    },
    getPlaylistDetails(channelId, playlistId) {
        return HTTP.get(`/channels/${channelId}/playlists/${playlistId}`);
    },
    upsertPlaylist(params) {
        const channelId = params.channelIds[0];
        const body = {
            title: params.title,
            description: params.description,
            platform: params.platform,
            status: params.status || "draft",
            external_id: params.externalId,
        };
        if (params.new_channel_id) {
            body.channel_id = params.new_channel_id;
        }
        if (params.playlistItems) {
            body.playlist_items = params.playlistItems;
        }
        if (params.id) {
            return HTTP.put(`/channels/${channelId}/playlists/${params.id}`, body);
        }

        return HTTP.post(`/playlists/post_item`, { ...body, channel_ids: params.channelIds });
    },
    addVideoToPlayList(params) {
        const { channelId, playlistId, videoId, position } = params;
        const body = {
            playlist_item: {
                video_id: videoId,
                position,
            }
        }
        return HTTP.post(`/channels/${channelId}/playlists/${playlistId}/add_video`, body);
    },
    copyPlaylistToChannel({ playlist_id, channel_id }) {
        return HTTP.post(`/playlists/copy_item`, { playlist_id, channel_id });
    },
    deletePlaylist(id) {
        return HTTP.delete(`/playlists/${id}`);
    },
    getCurrentUser() {
        return HTTP.get('/users/current_user');
    },
    getChannelAdmins(channel_id) {
        return HTTP.get(`/channels/${channel_id}/get_admins`);
    },
    generateSignupLink(channel_id, email, role) {
        return HTTP.post(`/channels/${channel_id}/signup_invitations`, { email, role: role || 'admin' });
    },
    revokeInvitation(id) {
        return HTTP.delete(`/signup_invitations/${id}`);
    },
    revokeAdmin(channel_id, user_id) {
        return HTTP.put(`/users/${user_id}/permissions`, { add_or_remove: false, channel_id })
    },
    appointAdmin(channel_id, user_id, role) {
        return HTTP.post(`/signup_invitations/appoint_admin`, { channel_id, user_id, role: role || 'admin' });
    },
    resendInvitation(channel_id, invitation_id) {
        return HTTP.post(`/channels/${channel_id}/signup_invitations/${invitation_id}/send_invitation`, {});
    },
    getInvitationByShortcode(shortcode) {
        return HTTP.get(`/signup_invitations/by_shortcode?shortcode=${shortcode}`);
    },
    acceptOrRejectInvitation({ email, name, password, shortcode, accept }) {
        return HTTP.post(`/signup_invitations/sign_up`, { email, name, password, shortcode, accept });
    },
    fetchCommboxMessages() {
        return HTTP.get('/commbox_conversations');
    },
    showCommboxMessage(id) {
        return HTTP.get(`/commbox_conversations/${id}`);
    },
    deleteCommboxMessage(id) {
        return HTTP.delete(`/commbox_conversations/${id}`);
    },
    resetPassword(userEmail) {
        return HTTP.post(`/users/forgot_password.json`, {
            email: userEmail,
        });
    },
    deleteUser(userId) {
        return HTTP.delete(`/users/${userId}`);
    },
}
